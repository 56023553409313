import React from 'react';
export default function () {
  return (
    <div className='pageNotFound' style={{textAlign:"center",marginTop:"100px"}}>
        <h1 className='page404' style={{color:"#7754F6",fontSize:"6.5rem",fontWeight:"bold"}}>404</h1>
        <h3 className='content' style={{color:"gray",backgroundColor:"white"}}>Page not found</h3>
        <p></p>
    </div>
  )
}
